import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./App.css";
import YouTube from "react-youtube";

import banner1 from "./img/banner1.jpg";
import banner2 from "./img/banner2.jpg";

import machine1 from "./img/machine1.jpg";
import machine2 from "./img/machine2.jpg";
import machine3 from "./img/machine3.jpg";
import machine4 from "./img/machine4.jpg";
import machine5 from "./img/machine5.jpg";
import machine6 from "./img/machine6.jpg";

const containerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 33.4996,
  lng: 126.5312,
};

const MenuZ = ({ onClick }) => {
  return (
    <div onClick={onClick} className="menuZ">
      회사소개
    </div>
  );
};

const MenuA = ({ onClick }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleMouseEnter = () => {
    setShowSubMenu(true);
  };

  const handleMouseLeave = () => {
    setShowSubMenu(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="menuA">
      시공공법
      {showSubMenu && (
        <div className="submenu-container">
          <SubMenu label="하위메뉴1" onClick={() => onClick("하위메뉴1이 선택되었습니다.")} />
          <SubMenu label="하위메뉴2" onClick={() => onClick("하위메뉴2가 선택되었습니다.")} />
        </div>
      )}
    </div>
  );
};

const MenuB = ({ onClick }) => {
  return (
    <div onClick={onClick} className="menuB">
      시공사례
    </div>
  );
};

const SubMenu = ({ label, onClick }) => {
  return (
    <div onClick={onClick} className="submenu">
      {label}
    </div>
  );
};

function App() {
  const [selectedMenu, setSelectedMenu] = useState("");
  const [slidesPerView, setSlidesPerView] = useState(6);
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [jsonData, setJsonData] = useState(null); // 데이터를 저장할 상태

  // data.json 파일 데이터를 가져오는 부분
  useEffect(() => {
    fetch("https://www.01056782586.net/data.json")
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((data) => {
        console.log("Access to JSON is allowed. Data:", data);
        setJsonData(data); // 가져온 데이터를 상태에 저장
        setPlaces(data.places || []); // places 데이터를 설정
        console.log("Places: ", data.places); // places 데이터 확인
      })
      .catch((error) => {
        console.error("Access to JSON is denied. Error:", error);
      });
  }, []);

  const handleMenuZClick = (message) => {
    console.log("Z메뉴 클릭됨");
    setSelectedMenu("Z메뉴가 선택되었습니다.");
  };

  const handleMenuAClick = (message) => {
    console.log("A메뉴 클릭됨");
    setSelectedMenu(message);
  };

  const handleMenuBClick = () => {
    console.log("B메뉴 클릭됨");
    setSelectedMenu("B메뉴가 선택되었습니다.");
  };

  const handleMarkerClick = (place) => {
    setSelectedPlace(place);
  };

  const handleInfoWindowClose = () => {
    setSelectedPlace(null);
  };

  useEffect(() => {
    const updateSlidesPerView = () => {
      const rootStyles = getComputedStyle(document.querySelector(".swiper-container"));
      const slides = parseInt(rootStyles.getPropertyValue("--swiper-slides-preview"));
      setSlidesPerView(slides);
    };

    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  const videoId = "F9pmCkTO51k";

  const opts = {
    height: "500px",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className="whole">
      <h1 className="title">(주)제라콘</h1>
      <div className="subtitle">
        제주도 제주시에 위치한 ‘주식회사 제라콘’은 2015년부터 2024년 현재까지, 83개의 공공기관, 197개의 까페, 212개의 상업 시설
        시공을 완료했습니다.{" "}
        <span className="red">
          제라콘의 대표적인 시공 사례로는 오설록, 국립제주박물관, 김녕농협 농산물유통센터, 하나로마트, 광역생활자원회수센터,
          대정읍사무소, 동부매립장, 제주참숯가마찜질방, 외도초등학교, 신성여고 등등이 있습니다.
        </span>{" "}
        착공부터 완공까지, ‘신속/정확/완벽’의 삼박자를 갖춘 제라콘의 시공력을 두 눈으로 직접 확인해 주십시오!
      </div>
      <br />
      <div>010-5678-2586</div>
      <div>블로그 연결</div>
      <div>유튜브 연결</div>
      <div>구글맵 연결</div>
      <div className="menu-container">
        <MenuZ onClick={handleMenuZClick} />
        <MenuA onClick={handleMenuAClick} />
        <MenuB onClick={handleMenuBClick} />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="selected-menu">{selectedMenu}</div>
      <Swiper spaceBetween={50} slidesPerView={1} navigation pagination={{ clickable: true }} modules={[Navigation, Pagination]}>
        <SwiperSlide>
          <img src={banner1} alt="Slide 1" className="swiper-slide-image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={banner2} alt="Slide 2" className="swiper-slide-image" />
        </SwiperSlide>
      </Swiper>
      <hr />
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
          {places.map((place) => (
            <Marker
              key={place.label}
              position={{ lat: place.lat, lng: place.lng }}
              label={place.label}
              title={place.name}
              onClick={() => handleMarkerClick(place)}
            />
          ))}
          {selectedPlace && (
            <InfoWindow position={{ lat: selectedPlace.lat, lng: selectedPlace.lng }} onCloseClick={handleInfoWindowClose}>
              <div>
                <h4>{selectedPlace.name}</h4>
                <p>
                  <img src={selectedPlace.photo} alt={selectedPlace.name} style={{ width: "100px" }} />
                </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        slidesPerView={slidesPerView}
        className="mySwiper swiper-container"
      >
        <SwiperSlide>
          <img src={machine1} alt="Slide 1" className="machine1-slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={machine2} alt="Slide 2" className="machine1-slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={machine3} alt="Slide 3" className="machine1-slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={machine4} alt="Slide 4" className="machine1-slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={machine5} alt="Slide 5" className="machine1-slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={machine6} alt="Slide 6" className="machine1-slide" />
        </SwiperSlide>
      </Swiper>
      <br />
      <YouTube videoId={videoId} opts={opts} />
      <br />
      <div className="machine">
        [장비현황]
        <br />
        <br />
        01. HTC 950RX - 2대
        <br />
        02. HTC 80iD 2 - 1대
        <br />
        03. HTC T5 - 2대
        <br />
        04. HTC 420VS - 1대
        <br />
        05. DURATIQ 8 - 1대
        <br />
        06. HTC 270EG - 1대
        <br />
        07. 보행식 습식청소차 SC510W - 1대
        <br />
        08. 고속광택기 - 1대
        <br />
        09. DS-3700AHL 청소기 - 2대
        <br />
        10. SS-3700AHL 청소기 - 4대
        <br />
        11. 크란즐 고압세척기 - 1대
        <br />
        12. 콘크리트 연삭기 - 1대
        <br />
        13. 콘크리트 평삭기 - 1대
        <br />
        14. 콘크리트 코너기 - 1대
        <br />
        15. SUPER-2020C2 - 1대
      </div>
      <div className="footer">
        주식회사 제라콘(JERACON) 지석희
        <br />
        전화 : 064-723-3090 / 010-5678-2586 팩스 : 064-751-3050
        <br />
        제주특별자치도 제주시 아봉로 548-11
        <br />
        사업자등록번호 : 365-86-01915
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* JSON 데이터를 화면에 표시하는 부분 */}
      {/* <div className="json-data">
        <h2>Data from data.json:</h2>
        {jsonData ? <pre>{JSON.stringify(jsonData, null, 2)}</pre> : <p>Loading data...</p>}
      </div> */}
    </div>
  );
}

export default App;
